/* eslint-disable no-promise-executor-return */
import { get, deleteRequest, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { Request } from 'types'
import { catchable } from 'utils'

export const RequestSignal = signal<Request[]>([])

export const deleteRequests = async (id: string) => {
  const response = await catchable(() => deleteRequest(`/miki/requests/${id}`))
  if ('error' in response) return
  RequestSignal.value = RequestSignal.value.filter(request => request.id !== id)
}

export const getRequests = async () => {
  const response = await catchable(() => get<Request[]>('/miki/requests'))
  if ('error' in response) return
  RequestSignal.value = response.sort(
    (a, b) => new Date(b.last_modified).getTime() - new Date(a.last_modified).getTime(),
  )
}

export const validateRequest = async (request: Request) => {
  await catchable(() => post(`/miki/requests/${request.id}/upload-done`, {}), true)
  await getRequests()
}

export const getExcel = async (request: Request) => {
  try {
    const response = await get(`/miki/requests/${request.id}/download-ep`, null, null, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })

    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = `${request.name}-${request.last_modified}-EP.xlsx`

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  } catch (error) {
    ToastSignal.value = {
      severity: ToastLevel.ERROR,
      message: terms.Messages.Download.failed,
    }
  }
}

export const uploadExcel = async (request: Request, file: File) => {
  const formData = new FormData()
  formData.append(`${request.name}-${request.last_modified}-EP.xlsx`, file)

  await new Promise(_ => setTimeout(_, 1000))
  await catchable(
    () => post(`/miki/requests/${request.id}/upload-ep`, formData),
    true,
    terms.Messages.Upload.failedExcel,
  )

  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Messages.Upload.successExcel,
  }
}
