import { useState } from 'react'
import { useMountEffect } from 'utils'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import terms from 'assets/terms'
import { getFolderSize, getRequests, uploadExcel } from 'services'
import { Button, ButtonSize, ButtonStyle, Loader, RequestModal } from 'components'
import { Request } from 'types'
import { ModalSignal } from '../ModalWrapper'

import './ExcelModal.scss'

type Props = {
  request: Request
  files: File[]
}

export default function ExcelUpload({ request, files }: Props) {
  const file = files[0]
  const [loading, setLoading] = useState(true)
  const [uploadedCount, setUploadedCount] = useState(0)
  const [uploadedMb, setUploadedMb] = useState(0)

  useMountEffect(() => {
    const uploadFiles = async () => {
      await uploadExcel(request, files[0])
      setUploadedCount(1)
      setUploadedMb(file.size / 1024 / 1024)
      setLoading(false)
    }

    uploadFiles()
  })

  const handleClose = () => {
    setLoading(false)
    getRequests()
    ModalSignal.value = undefined
  }

  const handleCancel = () => {
    ModalSignal.value = <RequestModal />
  }

  return (
    <>
      <h3>
        {terms.Modals.Excel.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <div className="folder-info">
        <p className="files-count">{`${uploadedCount} / ${files.length} ${terms.Modals.Excel.uploaded}`}</p>
        {loading ? <Loader variant="small" /> : <SuccessIcon />}
        <div>
          <b>{file.name}</b>
          <span>{`${Math.round(uploadedMb)} / ${getFolderSize(files)}  MB`}</span>
        </div>
      </div>
      {loading ? (
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleCancel}
        />
      ) : (
        <Button
          size={ButtonSize.medium}
          text={terms.Common.validate}
          onClick={handleClose}
        />
      )}
    </>
  )
}
