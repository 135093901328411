/* eslint-disable react/no-danger */
import terms from 'assets/terms'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button, ButtonSize, FileUpload, ModalSignal } from 'components'
import { Request } from 'types'
import ExcelError from './ExcelError'
import ExcelUpload from './ExcelUpload'

type Props = {
  request: Request
}

export default function ExcelModal({ request }: Props) {
  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleUpload = (files: File[]) => {
    ModalSignal.value = <ExcelUpload request={request} files={files} />
  }

  const handleError = (files: File[]) => {
    ModalSignal.value = <ExcelError request={request} files={files} />
  }

  return (
    <>
      <h3>
        {terms.Modals.Excel.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <FileUpload onError={handleError} onUploadReady={handleUpload} />
      <Button
        size={ButtonSize.medium}
        text={terms.Common.validate}
        disabled
      />
    </>
  )
}
