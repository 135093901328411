/* eslint-disable react/no-danger */
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import terms from 'assets/terms'
import { Request } from 'types'
import { Button, ButtonSize, ExcelModal } from 'components'
import { ModalSignal } from '../ModalWrapper'

import './ExcelModal.scss'

type Props = {
  files: File[]
  request: Request
}

export default function ExcelError({ files, request }: Props) {
  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleRetry = () => {
    ModalSignal.value = <ExcelModal request={request} />
  }

  return (
    <div className="request-error modal-content">
      <h3>
        {terms.Modals.Excel.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <div className="info">
        <WarningIcon />
        <p>{terms.Modals.Excel.error}</p>
      </div>
      <div className="meta">
        {files.map(file => (
          <div key={file.name}>{file.name}</div>
        ))}
      </div>
      <Button
        size={ButtonSize.medium}
        text={terms.Common.retry}
        onClick={handleRetry}
      />
    </div>
  )
}
