/* eslint-disable max-len */
export const terms = {
  Common: {
    disconnect: 'Déconnexion',
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    abort: 'Abandonner',
    save: 'Sauvegarder',
    back: 'Retour',
    confirm: 'Confirmer',
    delete: 'Supprimer',
    add: 'Ajouter',
    edit: 'Modifier',
    search: 'Rechercher',
    searching: 'Recherche',
    reset: 'Réinitialiser',
    filter: 'Filtrer',
    close: 'Fermer',
    continue: 'Continuer',
    validate: 'Valider',
    retry: 'Réessayer',
    new: 'Nouveau',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  Pages: {
    Home: {
      content: 'Home Page - Click me',
      newRequest: 'Nouvelle demande',
      noRequests: 'Il n’y a pas de demande en cours, veuillez faire une nouvelle demande.',
      requests: {
        excelDownload: 'Excel Pivot',
        import: 'Importer EP màj',
        name: 'Nom',
        status: 'Statut',
        lastUpdate: 'Dernière modification',
      },
    },
  },
  Components: {
    Upload: {
      info: (type: string) => `Glissez-déposez le ${type} ici ou<br /><b>sélectionnez le ${type} sur votre ordinateur</b>.`,
    },
  },
  Modals: {
    Request: {
      title: 'Créer une nouvelle demande',
      infoMain: 'Veuillez importer votre premier dossier.<br />Ses fichiers ne doivent pas dépasser 250Mo.',
      infoUpload: 'Cela peut prendre quelques minutes si le dossier est volumineux.',
      infoSuccess: 'Vous pouvez à présent créer la demande pour ce dossier.',
      error: (folder: string) => `Le dossier <b>${folder}</b> n’a pas pu être importé.<br />Veuillez recommencer l’import avec un nouveau dossier.`,
      uploaded: 'fichiers importés',
    },
    Excel: {
      title: 'Importer un Excel Pivot modifié',
      error: 'Erreur lors de l’import du fichier, veuillez réessayer.',
      uploaded: 'fichier importé',
    },
  },
  Messages: {
    Upload: {
      onFileOnly: 'Veuillez déposer un fichier uniquement.',
      oneFolderOnly: 'Veuillez déposer un dossier uniquement.',
      noFilesFoundInFolder: 'Aucun fichier trouvé dans le dossier déposé.',
      noFilesFound: 'Aucun fichier trouvé.',
      fileSizeLimit: 'Un fichier dépasse la limite de 250Mo.',
      failedExcel: 'Erreur lors de l’import du fichier Excel.',
      successExcel: 'Le fichier Excel a été importé avec succès.',
    },
    Download: {
      failed: 'Erreur lors du téléchargement du fichier Excel.',
    },
  },
} as const

export default terms
