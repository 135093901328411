import moment from 'moment'
import terms from 'assets/terms'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download-file.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { deleteRequests, getExcel, RequestSignal } from 'services'
import { Request } from 'types'
import { Button, ButtonSize, ButtonStyle, ExcelModal, ModalSignal } from 'components'

import './Requests.scss'

export default function Requests() {
  const requests = RequestSignal.value

  const handleImport = (request: Request) => () => {
    ModalSignal.value = <ExcelModal request={request} />
  }

  return (
    <div className="requests hide-scroll">
      <div className="list">
        <div className="head request">
          <p className="name">{terms.Pages.Home.requests.name}</p>
          <p className="status">{terms.Pages.Home.requests.status}</p>
          <p className="date">{terms.Pages.Home.requests.lastUpdate}</p>
          <div className="actions-status" />
          <div className="actions-request" />
        </div>
        {requests.map(request => (
          <div key={request.id} className="request">
            <Button
              style={ButtonStyle.borderLess}
              text={terms.Pages.Home.requests.excelDownload}
              icon={<DownloadIcon />}
              onClick={() => getExcel(request)}
            />
            <p className="name">{request.name}</p>
            <p className="status">{request.status}</p>
            <p className="date">{moment(request.last_modified).format('DD/MM/YYYY')}</p>
            <div className="actions-status">
              <Button
                text={terms.Pages.Home.requests.import}
                size={ButtonSize.medium}
                onClick={handleImport(request)}
              />
            </div>
            <div className="actions-request">
              <Button
                text=""
                style={ButtonStyle.borderLess}
                icon={<DownloadFileIcon />}
              />
              <Button
                text=""
                style={ButtonStyle.borderLess}
                icon={<DeleteIcon />}
                onClick={() => deleteRequests(request.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
