/* eslint-disable react/no-danger */
import terms from 'assets/terms'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button, ButtonSize, FolderUpload, ModalSignal } from 'components'
import RequestError from './RequestError'
import RequestUpload from './RequestUpload'

export default function RequestModal() {
  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleUpload = (files: File[]) => {
    ModalSignal.value = <RequestUpload files={files} />
  }

  const handleError = (files: File[]) => {
    ModalSignal.value = <RequestError files={files} />
  }

  return (
    <>
      <h3>
        {terms.Modals.Request.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p className="modal-info" dangerouslySetInnerHTML={{ __html: terms.Modals.Request.infoMain }} />
      <FolderUpload onError={handleError} onUploadReady={handleUpload} />
      <Button
        size={ButtonSize.medium}
        text={terms.Common.validate}
        disabled
      />
    </>
  )
}
